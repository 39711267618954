import Container from './Container';
import CTA from './CTA';
import Message from './Message';
import Richtext from './Richtext';
import Table from './Table';
import Image from './Image';
import Links from './Links';
import Accordions from './Accordions';
import Video from './Video';
import Tabs from './Tabs';
import Cards from './Cards';
import BonusweltSlider from './BonusweltSlider';
import Columns from './Columns';
import Divider from './Divider';
import Quote from './Quote';

const streamfields = {
  cta: CTA,
  message: Message,
  full_richtext: Richtext,
  simple_richtext: Richtext,
  table: Table,
  code: Richtext,
  container: Container,
  image: Image,
  links: Links,
  accordion: Accordions,
  video: Video,
  tabs: Tabs,
  cards: Cards,
  columns: Columns,
  divider: Divider,
  quote: Quote,
  bonuswelt_slider: BonusweltSlider,
};

export type Streamfields = keyof typeof streamfields;

export default streamfields;
