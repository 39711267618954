import React from 'react';
import Image from 'next/image';
import { WagtailStreamfieldImage } from '@/types';
import { addBaseUrl } from '@/utils/url';
import RawHtml from '@components/RawHtml';

type Props = {
  title?: string;
  iconimage: WagtailStreamfieldImage | null;
  iconsvg?: string;
  table: {
    data: string[][];
    first_row_is_table_header: boolean;
    first_col_is_header: boolean;
    table_caption?: string;
  };
};

const Table = (props: Props) => {
  const {
    title,
    iconimage,
    iconsvg,
    table: {
      data,
      first_row_is_table_header,
      first_col_is_header,
      table_caption,
    },
  } = props;

  return (
    <div className="streamfield table">
      {title ? <h2 className="table__title">{title}</h2> : null}
      <div className="bg-wrapper">
        {iconimage ? (
          <div className="table__icon">
            <Image
              src={addBaseUrl(iconimage.file.src)}
              alt={iconimage.file.alt}
              layout="intrinsic"
              objectFit="contain"
              width={78}
              height={78}
            />
          </div>
        ) : iconsvg ? (
          <div className="table__icon">
            <RawHtml className="table__icon" html={iconsvg} />{' '}
          </div>
        ) : null}
        <table className="table__element">
          {table_caption ? (
            <caption className="table__caption">{table_caption}</caption>
          ) : null}
          {first_row_is_table_header ? (
            <thead className="table__header">
              <tr className="table__row">
                {data[0].map((th, index) => (
                  <th className="table__head" key={`${th}${index}`}>
                    {th}
                  </th>
                ))}
              </tr>
            </thead>
          ) : null}
          <tbody className="table__body">
            {data.map((row, index) => {
              if (first_row_is_table_header && index === 0) {
                return null;
              }
              return (
                <tr className="table__row" key={index}>
                  {row.map((td, index) =>
                    first_col_is_header && index === 0 ? (
                      <th className="table__head" key={`${td}${index}`}>
                        {td ?? ''}
                      </th>
                    ) : (
                      <td className="table__data" key={`${td}${index}`}>
                        {td ?? ''}
                      </td>
                    ),
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
