import React, { useEffect, useState } from 'react';
import { get_companies } from './ApiCalls';
import { Company } from './models';
import Richtext from '@streamfields/Richtext';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import Link from 'next/link';

import BonusweltCompany from '@components/BonusweltCompany';

interface Props {
  title: string;
  description: string;
  companies: Array<Record<'company', Company>>;
}

const BonusweltSlider: React.FC<Props> = props => {
  const companies = props.companies.map(c => c.company);

  return (
    <div className="bonuswelt">
      <div className="bonuswelt__before_slide">
        <h2 className="bonuswelt__title">{props.title}</h2>
        <div className="bonuswelt__description">
          <Richtext sourcecode={props.description}></Richtext>
        </div>
      </div>
      <div className="bonuswelt__splide">
        <Splide
          options={{
            rewind: true,
            perPage: 2,
          }}
        >
          {companies.map((company, index) => (
            <SplideSlide key={index}>
              <BonusweltCompany company={company}></BonusweltCompany>
            </SplideSlide>
          ))}
        </Splide>
      </div>

      <div className="bonuswelt__after_slide">
        <div className="bonuswelt__button_uebersicht">
          <Link href="/bonuswelt">
            <button className="button button--primary">
              Zur Angebotsübersicht
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BonusweltSlider;
