import React from 'react';
import RawHtml from '@components/RawHtml';
import { WagtailImage } from '@/types';
import Image from 'next/image';
import { addBaseUrl } from '@/utils/url';

type Props = {
  title: string;
  subtitle?: string | null;
  text?: string | null;
  banner_icon_svg?: string | null;
  banner_icon_png?: WagtailImage | null;
};

const Hero = (props: Props) => {
  const { title, subtitle, text, banner_icon_svg, banner_icon_png } = props;

  return (
    <div role="banner" className="hero flow">
      {banner_icon_png ? (
        <div className="hero__icon">
          <Image
            src={addBaseUrl(banner_icon_png.meta.download_url)}
            alt={banner_icon_png.title}
            layout="intrinsic"
            objectFit="contain"
            className="icon"
            width={78}
            height={78}
          />
        </div>
      ) : banner_icon_svg ? (
        <RawHtml className="hero__icon" html={banner_icon_svg} />
      ) : null}
      <h1 className="hero__title">{title}</h1>
      {subtitle ? <h2 className="hero__subtitle">{subtitle}</h2> : null}
      {text ? <RawHtml className="hero__text" html={text} /> : null}
    </div>
  );
};

export default Hero;
