import React, { PropsWithChildren } from "react";
import Head from "next/head";

type SeoProps = {
  seo_html_title: string;
  seo_meta_description: string;
  seo_og_title: string;
  seo_og_description: string;
  seo_og_url: string;
  seo_og_image: string;
  seo_og_type: string;
  seo_twitter_title: string;
  seo_twitter_description: string;
  seo_twitter_url: string;
  seo_twitter_image: string;
  seo_meta_robots: string;
  canonicalLink: string;
};

const defaultSeoProps: Partial<SeoProps> = {};

export type Props = {
  title: string;
  seo: Partial<SeoProps>;
};

const BasePage = (props: PropsWithChildren<Props>) => {
  const { children, seo = defaultSeoProps, title } = props;

  const {
    seo_html_title,
    seo_meta_description,
    seo_og_title,
    seo_og_description,
    seo_og_url,
    seo_og_image,
    seo_og_type,
    seo_twitter_title,
    seo_twitter_description,
    seo_twitter_url,
    seo_twitter_image,
    seo_meta_robots,
    canonicalLink,
  } = seo;

  return (
    <>
      <Head>
        <title>{seo_html_title ?? title}</title>
        <link rel="icon" href="/favicon.ico" />
        {seo_meta_description ? (
          <meta name="description" content={seo_meta_description} />
        ) : null}
        {seo_og_title ? (
          <meta property="og:title" content={seo_og_title} />
        ) : null}
        {seo_og_description ? (
          <meta property="og:description" content={seo_og_description} />
        ) : null}
        {seo_og_url ? <meta property="og:url" content={seo_og_url} /> : null}
        {seo_og_image ? (
          <meta property="og:image" content={seo_og_image} />
        ) : null}
        {seo_og_type ? <meta property="og:type" content={seo_og_type} /> : null}
        {seo_twitter_title ? (
          <meta property="twitter:title" content={seo_twitter_title} />
        ) : null}
        {seo_twitter_description ? (
          <meta
            property="twitter:description"
            content={seo_twitter_description}
          />
        ) : null}
        {seo_twitter_url ? (
          <meta property="twitter:url" content={seo_twitter_url} />
        ) : null}
        {seo_twitter_image ? (
          <meta property="twitter:image" content={seo_twitter_image} />
        ) : null}
        <meta name="robots" content={seo_meta_robots} />
        {canonicalLink ? <link rel="canonical" href={canonicalLink} /> : null}
      </Head>
      <div className="base-page">{children}</div>
    </>
  );
};

export default BasePage;
