import React from 'react';
import { Company } from '@streamfields/BonusweltSlider/models';

import '@splidejs/splide/dist/css/splide.min.css';
import Link from 'next/link';

import NextImage from 'next/image';
import { addBaseUrl } from '@/utils/url';

interface Props {
  company: Company;
}

const get_substr = (text: string) => {
  const TEXT_LENGTH = 100;
  let new_text = text.substring(0, TEXT_LENGTH);
  if (text.length > TEXT_LENGTH) {
    new_text += ' ...';
  }
  return new_text;
};

const BonusweltCompany: React.FC<Props> = props => {
  const { company } = props;
  return (
    <div className="bonuswelt_company">
      <div className="bonuswelt_company__image">
        <NextImage
          className="image__element"
          src={addBaseUrl(company.offer_obj.image_obj.file.src)}
          alt={company.offer_obj.image_obj.file.alt}
          layout="responsive"
          width={company.offer_obj.image_obj.file.width}
          height={company.offer_obj.image_obj.file.height}
        />
      </div>
      <div className="bonuswelt_company__container">
        <div className="bonuswelt_company__logo">
          <NextImage
            className="image__element"
            src={addBaseUrl(company.logo_obj.file.src)}
            alt={company.logo_obj.file.alt}
            layout="fixed"
            width={company.logo_obj.file.width}
            height={company.logo_obj.file.height}
          />
        </div>
        <div className="bonuswelt_company__name">
          <h3>{company.name}</h3>
        </div>
        <div className="bonuswelt_company__description">
          {get_substr(company.offer_obj.plain_description)}
        </div>
        <div className="bonuswelt__button">
          <Link href={'/bonusweltdetail?id=' + company.id}>
            Angebotsdetails
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BonusweltCompany;
