import { Streamfield } from '@/types';
import Streamfields from '@streamfields/Streamfields';
import clsx from 'clsx';
import React from 'react';

type BackgroundColors =
  | 'light-grey'
  | 'light-blue'
  | 'white'
  | 'gradient-light-blue-white'
  | 'gradient-light-blue-dark-blue';

type Props = {
  full_width: boolean;
  content: Streamfield[];
  background: BackgroundColors;
};

const Container = (props: Props) => {
  const { content, background, full_width } = props;
  return (
    <div
      className={clsx('streamfield', 'container-field', {
        'container-field--full-width': full_width,
        'container-field--light-grey': background === 'light-grey',
        'container-field--gradient-light-blue-dark-blue':
          background === 'gradient-light-blue-dark-blue',
        'container-field--gradient-light-blue-white':
          background === 'gradient-light-blue-white',
      })}
    >
      {content ? (
        <div className={clsx({ container: full_width })}>
          <Streamfields fields={content} />
        </div>
      ) : null}
    </div>
  );
};

export default Container;
