import { WagtailImage } from '@/types';
import React from 'react';
import Image from 'next/image';
import { addBaseUrl } from '@/utils/url';

type Props = {
  image: WagtailImage;
};

const BannerImage = (props: Props) => {
  const { image } = props;

  return (
    <div className="banner-image">
      <Image
        src={addBaseUrl(image.meta.download_url)}
        alt={image.title}
        layout="fill"
        objectFit="cover"
        priority
      />
    </div>
  );
};

export default BannerImage;
