import Link from 'next/link';
import React from 'react';
import clsx from 'clsx';
import { removeBaseUrl } from '@/utils/url';
import { WagtailPage } from '@/types';
import Image from 'next/image';
import { WagtailStreamfieldImage } from '@/types';
import { addBaseUrl } from '@/utils/url';
import RawHtml from '@components/RawHtml/RawHtml';

type Props = {
  title: string;
  view: string;
  text: string;
  image: WagtailStreamfieldImage;
  button_page: WagtailPage | null;
  button_url: string | null;
  button_text: string;
};

const CTA = (props: Props) => {
  const { button_page, button_url, button_text, title, view, image, text } =
    props;

  return (
    <div className="streamfield cta">
      <div className="container">
        <div className="box">
          {title ? <div className="box__header">{title}</div> : ''}
          <div className="box__text">
            <RawHtml html={text} />
          </div>
          <Link
            href={
              button_page
                ? removeBaseUrl(button_page.full_url)
                : button_url ?? ''
            }
            passHref
          >
            <a className="button button--primary">{button_text}</a>
          </Link>
        </div>
      </div>
      <Image
        src={addBaseUrl(image.file.src)}
        alt={image.file.alt}
        layout="fill"
        objectFit="cover"
        className="cta__image"
      />
    </div>
  );
};

export default CTA;
